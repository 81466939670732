<template>
    <div class="row">
        <div class="col-md-12">
            <button style="margin-top: 10px; margin-left: 10px" @click="$router.push('casketadd')" class="btn btn-primary">Add Casket to Inventory</button><br>
            <button style="margin-top: 10px; margin-left: 10px" @click="printData()" class="btn btn-primary">Print Casket Inventory</button><br>
            <button style="margin-top: 10px; margin-left: 10px" @click="$router.push('casketreconcile')" class="btn btn-primary">Update Inventory</button>
            <br>
            <h3 style="width: 75%; margin-left:auto;
margin-right:auto;"><input type='text' class="form-control" placeholder="Search..." v-model="search"/></h3>
            <div class="form-group" style="float:right; margin-right: 20px">
                <label>Availability</label>
                <select class=form-control v-model="brandfilter">
                    <option value="All Caskets">All Caskets</option>
                    <option value="Available">Available</option>
                </select>
            </div>
            <table class="table table-striped">
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Color</th>
                        <th>Quantity</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="casket in filteredSearch2" :key="casket.key">
                        <td v-if="casket.available == true">{{ casket.name }}</td>
                        <td v-if="casket.available == false" style="background-color: red; color: white">{{ casket.name }}</td>
                        <td>{{ casket.color }}</td>
                        <td>{{ casket.amount }}</td>
                        <td>
                            <router-link :to="{name: 'casketEdit', params: { id: casket.key }}" class="btn btn-primary">Edit
                            </router-link>
                            <button @click.prevent="deleteCasket(casket.key)" class="btn btn-danger">Delete</button>
                        </td>
                    </tr>
                </tbody>
            </table>
            
            <table style="border: 1px solid black; width: 100%; padding: 20px;" id="printTable">
                <thead>
                    <tr style="padding: 10px; text-align: center">
                        <th style="border: 1px solid black; ">Name</th>
                        <th style="border: 1px solid black; padding: 10px;">Quantity</th>
                        <th style="border: 1px solid black; padding: 10px;">Corrections</th>
                    </tr>
                </thead>
                <tbody style="border: 1px solid black">
                    <tr style="border: 1px solid black;" v-for="casket in filteredSearch2" :key="casket.key">
                        <td style="border: 1px solid black; padding: 10px; text-align: center" v-if="casket.available == true">{{ casket.name }}</td>
                        <td style="border: 1px solid black; padding: 10px; text-align: center">{{ casket.amount }}</td>
                        <td style="border: 1px solid black; padding: 10px; text-align: center"></td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
    function printData()
    {
   var divToPrint=document.getElementById("printTable");
   var newWin= window.open("");
   document.getElementById("printTable").style.display = "block";   
   newWin.document.write(divToPrint.outerHTML);
   newWin.print();
   newWin.close();
   document.getElementById("printTable").style.display = "none";   
    }

    import { db } from '../firebaseDb';
    export default {
        data() {
            return {
                Caskets: [],
                currentTenant: "",
                service: {},
                serviceid: "",
                newCem: {},
                brandfilter: "Available",
                search: "",
            }
        },
        computed: {
            filteredSearch:function() {
                return this.Caskets.filter((casket) => {
                    if(this.brandfilter == "All Caskets") {
                        var filterName = casket.name + casket.color;
                        return filterName.toLowerCase().match(this.search.toLowerCase());
                    }
                    if(this.brandfilter == "Available") {
                        var theService = String(casket.available);
                        //var filterName2 = casket.name + casket.color;
                        return theService.match("true")//+filterName2.toLowerCase().match(this.search.toLowerCase());
                    }
                })
            },
            filteredSearch2:function() {
                return this.filteredSearch.filter((casket) => {
                    var theCasket = casket.name;
                    return theCasket.toLowerCase().match(this.search.toLowerCase());
                })
            }
        },
        created() {
            this.currentTenant = this.$tenant;
            this.updateservices();
        },
        mounted() {
          document.getElementById("printTable").style.display = "none";   
        },
        methods: {
            printData() {
              printData()  
            },
            updateservices() {
                db.collection('caskets').orderBy('color').orderBy('name').onSnapshot((snapshotChange) => {
                this.Caskets = [];
                snapshotChange.forEach((doc) => {
                    if(doc.data().tenant == this.currentTenant && doc.data().deleted !== true) {
                    if(doc.data().amount <= 0) {
                        var newCasket = doc.data();
                        newCasket.available = false;
                        db.collection('caskets').doc(doc.id).update(newCasket);
                    }
                    if(doc.data().amount >= 1 && doc.data().available == false) {
                        var newCasket2 = doc.data();
                        newCasket2.available = true;
                        db.collection('caskets').doc(doc.id).update(newCasket2);
                    }
                    this.Caskets.push({
                        key: doc.id,
                        name: doc.data().name,
                        notes: doc.data().notes,
                        available: doc.data().available,
                        color: doc.data().color,
                        amount: doc.data().amount,
                    })
                    }
                });
            })
            },
            deleteCasket(id){
              if (window.confirm("Do you really want to delete?")) {
                this.newCem = this.Caskets.find(x => x.key === id)
                console.log(this.newCem)
                this.newCem.deleted = true;
                if(this.newCem.notes == null) {
                    this.newCem.notes = ""
                }
                db.collection("caskets").doc(id).update(this.newCem).then(() => {
                    console.log("Cemetery deleted!");
                })
                .catch((error) => {
                    console.error(error);
                })
              }
            }
        }
    }
</script>

<style>
    .false {
        background-color: red;
    }
    .btn-primary {
        margin-right: 12px;
    }
</style>